<template>
  <div>
          <S1 :prop="prop" :imgsrc="imgsrc"  />
          <S2 :prop="prop" :imgsrc="imgsrc" :id="id" />

  </div>
</template>

<script>
export default {
props:['prop', 'imgsrc', 'id'],
 components: {
    S1: () => import("../components/project/s1.vue"),
    S2: () => import("../components/project/s3.vue"),

  },
}
</script>

<style>

</style>